.cover-page .card-img-top {
  /* height: 35px; */
  height: 32.32px;
  width: 45px;
  transition: trasnform 330ms ease-in-out !important;
}

.cover-page .card-img-top:hover {
  transform: scale(1.3);
  transition: trasnform 330ms ease-in-out !important;
}

.cover-page .card {
  min-width: auto !important;
  min-height: auto;
  align-items: center;
  padding: 10px 10px 10px;
  width: 101px;
  height: 80px;
  border: none;
  border-radius: 0;
  background-color: var(--background);
  color: var(--color-text);
}

.cover-page .card-body {
  padding: 0;
}

.col {
  display: flex;
  justify-content: center;
}

.cover-page h5 {
  width: auto !important;
  background-color: transparent;
  border: none;
  font-size: 10px !important;
  margin-top: 8px;
  color: var(--color-text);
}

.cover-page .card-text {
  justify-content: center;
  text-align: center;
  color: var(--color-text);
}

.up-logos .col {
  /* width: 8%; */
  width: 100%;
  margin: 0 10px;
}

.cover-page a {
  text-decoration: none !important;
}

@media screen and (min-width: 350px) and (max-width: 1000px) {
  .cover-page .card-img-top {
    width: 30px !important;
    height: 24px !important;
    transition: trasnform 330ms ease-in-out !important;
  }

  .up-logos .col {
    width: 23.5% !important;
  }

  .cover-page .card {
    padding-bottom: 0 !important;
    height: 60px !important;
  }
}
