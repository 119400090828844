.learn-tabs .MuiButtonBase-root.MuiTab-root{
  color: var(--color-text);
  text-transform: none;
  font-size: 10px;
}

.learn-tabs .MuiTabs-indicator{
  background-color: var(--color-text);
  height: 0.5px;
}

.learn-tabs{
  position: sticky;
  top: 0;
}

.MuiAccordionDetails-root{
  padding-top: 0 !important;
}

.step-img:hover{
  border: 2px solid #585858;

}

.css-rqaqn2-MuiPaper-root-MuiAccordion-root:before{
  display: none;
}