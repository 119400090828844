.learn-tabs .MuiButtonBase-root.MuiTab-root{
  color: var(--color-text);
  text-transform: none;
  font-size: 10px;
}

.learn-tabs .MuiTabs-indicator{
  background-color: var(--color-text);
  height: 0.5px;
}

.learn-tabs{
  position: sticky;
  top: 0;
}