
.MuiTableContainer-root {
    background-color: var(--background) !important;
  }

  .MuiTableBody-root .MuiTableRow-root:nth-child(odd){
    background-color: var(--background_lighter) !important;
  }

  .MuiTableBody-root .MuiTableRow-root:nth-child(even){
    background-color: var(--background) !important;
  }

  .MuiTableCell-root {
    color: var(--color-text) !important;
    border-bottom: none !important;
  }

  .MuiSvgIcon-root{
    vertical-align: baseline !important;
  }

  @media (max-width: 767px) {
    .MuiTableCell-root {
      padding: 8px !important;
      font-size: 10px !important;
    }
  }