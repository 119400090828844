.home-container {
    width: 100%;
    height: 710px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    /* background-size: 100vw; */
    background-size: cover;
    flex-direction: column;
    background-color: #fff;
    background-image: url("../../assets/Wall\ street\ new\ banner.svg");
    color: #fff;
    
  }
  
  .home-container-mobile {
    width: 100%;
    height: 1210px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    flex-direction: column;
    color: #fff;
    overflow: hidden;
  }


.card__title{
    color: var(--color-text);
}

/* .cover-page .card-img-top{
    width: 35px;
    height: 32.32px;
    transition: trasnform 330ms ease-in-out !important;
}

.cover-page .card-img-top:hover{
    transform: scale(1.3);
    transition: trasnform 330ms ease-in-out !important;
}

.cover-page .card{
    min-width: auto !important;
    min-height: auto;
    align-items: center;
    padding: 50px 10px 10px;
    width: 100px;
    height: 80px;
    border: none;
}

.cover-page .card-body{
    padding: 0;
}
.cover-page .col{
    width: 23%;
}

.cover-page  h5{
    width: auto !important;
    background-color: transparent;
    border: none;
    font-size: 10px !important;
    margin-top: 8px;
    color: var(--color-text);

}

.cover-page .card-text{
    justify-content: center;
    text-align: center;
    color: var(--color-text);
} */



.main-page .card{
    min-width: auto !important;
    min-height: auto;
    align-items: center;
    padding: 0px;
    /* width: 318.87px;
    height: 250px; */
    border-radius: 2px;
    border: none;
    /* background-color: var(--card-color); */
}

/* .main-page .card-img-top{
    width: 318.87px;
    height: 200px;
} */

.main-page .col{
    width: 29%;
    display: flex;
    justify-content: center;
}

.g-4, .gy-4 {
    --bs-gutter-y: 4rem;
}

.main-page h5{
    width: auto !important;
    background-color: transparent;
    border: none;
    font-size: 20px;
    margin-top: 5px;
}

.main-page .card-text{
    padding: 12px;
    justify-content: center;
    text-align: center;
}


.main-page .ant-btn-default{
    width: 318.87px;
    background-color: #0294fe !important;
    color: var(--color-text);
    font-size: 15px;
    height: auto;
    padding: 10px;
    border-color: var(--border-color);
}

.main-page .ant-btn-default:hover{
    background-color: #0057ff !important;

}


@media (max-width: 767px) {

.home-container {
    height: 400px;
    
  }
}