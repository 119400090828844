.collectionCard {
  z-index: 600;
  transition: all 0.2s ease-in;
}

.collectionCard:hover {
  transform: translateY(-5px);
}

.cardBody {
  display: flex;
  flex-direction: column;
  /* border-radius: 20px; */
  padding: 1rem;
  padding-bottom: 2rem;
  z-index: 10;
  cursor: pointer;
}

.imageStyle {
  /* height: 184.21px; */
  width: 100%;
  /* border-radius: 20px; */
  object-fit: cover;
}

.collectionCardBody {
  margin-top: 24px;

}

.collectionCardInfo {
  display: flex;
  justify-content: space-between;
}

.collectionCardCreator {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.collectionCardOwner {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.dividerStyle {
  background-color: #8e8e8e;
  margin: 10px 0;
}

.collectionCardFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.link-style:hover {
  color: #006DFF;
}