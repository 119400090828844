/* App.css */
  /* h1 {
    color: #333;
    font-weight: 600;
    margin-bottom: 20px;
  } */
  
  select {
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
    outline: none;
    margin-bottom: 30px;
    font-size: 16px;
  }
  
  .container {
    margin: auto;
    max-width: 1250px;
  }
  
  .graph {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
  
  .graph h3 {
    text-align: center;
  }
  
  .graphBackground {
    background-color: white;
    width: auto;
    height: auto;
  }
  
  .xscroll {
    overflow: scroll;
  }
  
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #112d3f;
    color: white;
    width: 85px;
    height: 23px;
    margin-right: 3rem;
    cursor: pointer;
    border: none;
    margin: 0.4rem;
    border-radius: 5px;
  }
  
  .button:hover {
    background-color: #236086;
  }
  
  .Chart_inner {
    padding: 10px 0;
  }
  
  .chart_inner_right .chart_dynamic .ant-segmented-item-label {
    width: 65px;
    margin-top: -30;
  }
  
  .chart_inner_right .chart_dynamic label {
    padding-bottom: 0;
  }
  
  /* .chart_header {
      display:flex;
      align-items:center;
  } */
  .chart_header h1 {
    margin-bottom: 0;
    font-size: 30px;
    color: #5F5F5F;
    line-height: 1;
  }
  
  .chart_title {
    padding: 0 7px;
  }
  
  .arrow_container div {
    line-height: 0;
    padding-bottom: 2px;
  }
  
  .Chart_inner {
    display: flex;
    justify-content: space-between;
    line-height: 1;
    padding: 12px 0;
    align-items: baseline;
    /* padding-right: 76px; */
  }
  
  /* .chart_inner_left_top {
    display:flex;
  } */
  
  .chart_inner_middle {
    color: #F66036;
    font-size: 20px;
  }
  
  .chart_inner_right .ant-segmented {
    /* background-color: rgba(129, 129, 129, 0.1); */
    font-size: 15px;
    /* color: #5f5f5f; */
    padding: 0;
    border-radius: 5px;
  }
  
  .chart_inner_right .ant-segmented-item-label {
    width: 65px;
    font-size: 15px;
    min-height: 37px;
    line-height: 37px;
  }
  
  .ant-segmented{
    background-color: transparent !important;
  }
  
  .chart_inner_right .ant-segmented-item:hover {
    color: var(--color-text) !important;
  }
  
  .chart_inner_right .chart_dynamic label {
    padding-bottom: 0;
  }
  
  .chart_inner_right .chart_dynamic .ant-segmented-item-label {
    width: 65px;
  }
  
  .ant-segmented-item-selected, .ant-segmented-thumb {
    background-color: var(--timeline) !important;
    color: var(--color-text) !important;
  }
  
  .time {
    /* display:flex;
    justify-content: space-between; */
    padding: 10px 15px;
  }
  
  .custom-tooltip {
    color: var(--color-text);
}

  .time div {
    color: #5F5F5F;
    font-size: 20px;
  
  }
  
  .tooltip {
    border-radius: 0.25rem;
    background: black;
    color: #fff;
    padding: 1rem;
    box-shadow: 15px 30px 40px 5px rgba(0, 0, 0, 0.5);
    text-align: center;
  }
  
  .chart_inner_right .ant-segmented {
    margin-top: -50;
  }
  
  .chart_inner_right .chart_dynamic .ant-segmented-item-label {
    width: 58px;
    font-size: 15px;
    min-height: 37px;
    /* background:rgb(255, 255, 255) ; */
    line-height: 37px;
  }
  
  .chart_inner_right .chart_dynamic .ant-segmented-item-selected {
     /* background: rgb(75, 15, 15); */
    /* color: #fff; */
    border: none;
    box-shadow: none;
    font-weight: bolder;
  }
  
  .recharts-tooltip {
    /* background-color: red; */
    /* border: 1px solid gray; */
    padding: 10px;
    /* pointer-events: none;
    position: absolute;
    z-index: 999; */
  }
  
  .recharts-tooltip-label {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .recharts-tooltip-item-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .recharts-tooltip-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .recharts-tooltip-item-name {
    font-weight: bold;
    margin-right: 10px;
  }
  
  .recharts-tooltip-item-value {
    color: gray;
  }
  
  .recharts-tooltip-pointer {
   /* width: 100;
    height: 100;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid gray;
    /* position: absolute; */
    /*top: -5px;
    left: 500%;
    margin-left: -5px;
    pointer-events: none;
    position: absolute;
    display: block;
    opacity: 1;
    width: 100%;
    opacity: 0.7;
    z-index: 1000000;
    visibility: visible;
  */
  width: 100;
  height: 100;
  border-left: 5px solid transparent;
  border-right: 23px solid transparent;
  /* border-bottom: 5px solid gray; */
  border-left: 1px solid gray;
  height: 365px;
  /* position: absolute; */
  top: -5px;
  left: 500%;
  margin-left: 56px;
  margin-top: 21px;
  /* pointer-events: none; */
  /* position: absolute; */
  /* display: block; */
  /* width: 100%; */
  opacity: 0.7;
  z-index: 1000000;
  visibility: visible;
  }
  
  
  @media (max-width: 767px) {
  
  .my-time{
    padding: 8px !important;
    min-width: 60px !important;
    min-height: 20px !important;
  }

  .main-tab{
    min-height: 20px !important;

  }
}