:root {
    --color-text: #e8e8ed;
    --background_lighter:#171717;
    --background:#000;
    --disabled-btn:#3231319f;
    --disabled-btn-text:rgba(135, 132, 132, 0.785);
    --border-color:#2c2c2c;
    --timeline:#3231319f;
    --alert-bkg:#3d464a;
    --alert-border: #51616a;
    --dark_text:#fff;
    --table-light:#040404;
    --table-border:  #3c3b3b;
    --scroller:46, 46, 46;
  }

[data-bs-theme="light"] {

    --color-text: #333336;
    --background_lighter:#eeeeee;
    --background: white;
    --disabled-btn:#f5f5f5;
    --disabled-btn-text:rgba(0, 0, 0, 0.25);
    --border-color:#5f5f5f36;
    --timeline:#e0e0e0;
    --alert-bkg:#e6f7ff;
    --alert-border: #91d5ff;
    --dark_text:#000;
    --table-light:#eeeeee;
    --table-border:  #E6E8EA;
    --scroller: 193, 193, 193;
  }


  body {
  margin: 0;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* padding: 0 !important; */
  /* overflow: auto !important; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

